import React, { useState, useEffect, useCallback } from "react";
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import { Button, UncontrolledTooltip } from "reactstrap";
import classnames from "classnames";
import OrderDoc from "model/orderDoc";
import { getBeUrl, getNotarizationRequired, showError, showSuccess } from "helpers/utilHelper";
import { useSocketOn, useSubscribeToOrderDoc } from "hooks/socket";
import socketEvent from 'constants/socketEvent';
import { deleteOrderDocNormListItem, getOrderDocNormListItem, reprocessOrderDocNormListItem } from "store/actions";
import 'photoswipe/dist/photoswipe.css';
import Confirmation from "components/Shared/Confirmation";
import DocViewer from "components/Shared/DocViewer";
import esigndoc from 'assets/images/esigndoc.svg';
import inksigndoc from 'assets/images/inksigndoc.svg';
import filedoc from 'assets/images/filedoc.svg';
import previewdoc from 'assets/images/previewdoc.svg';
import supportdoc from 'assets/images/supportdoc.svg';
import Order from "model/order";

const FormNewDocumentsDoc = props => {

  const { id, num, refreshListHandler, deliveryOption } = props;

  // redux hook that dispatches actions
  const dispatch = useDispatch();

  /********** STATE **********/

  const orderDoc = useSelector(state => state.OrderDoc.NormList.orderDocs[id]);

  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const [isEditActive, setIsEditActive] = useState(false);

  /********** EFFECTS **********/

  // runs whenever the order doc is deleted
  useEffect(() => {
    if (orderDoc.isDeleted === true) {
      showSuccess(`Document "${orderDoc.name}" has been deleted`);
      refreshListHandler();
    } else if (orderDoc.isDeleted === false) {
      showError('Unable to delete document');
    }
  }, [orderDoc.isDeleted]);

  // runs whenever processing is restarted for the order doc
  useEffect(() => {
    if (orderDoc.isReprocessed === true) {
      refreshOrderDoc();
    } else if (orderDoc.isReprocessed === false) {
      showError('Unable to restart processing for document');
    }
  }, [orderDoc.isReprocessed]);

  /********** OTHER **********/

  const refreshOrderDoc = useCallback(() => dispatch(getOrderDocNormListItem(orderDoc.id)), [orderDoc.id]);

  /********** SOCKET **********/

  // start receiving updates about this particular document
  useSubscribeToOrderDoc(orderDoc.id);

  const onOrderDocChanged = useCallback(data => {
    // this socket client is shared by the entire app
    // and here we are listening for an event that might be triggered by multiple documents
    // therefore we need to check whether this update refers to the right document
    if (data.id == orderDoc.id) {
      refreshOrderDoc();
    }
  }, [orderDoc.id, refreshOrderDoc]);

  // listen for changes on order documents
  useSocketOn(socketEvent.orderDocChanged, onOrderDocChanged);

  /********** EVENT HANDLERS **********/

  const removeOrderDoc = () => dispatch(deleteOrderDocNormListItem(orderDoc.id));

  const restartProcessing = () => dispatch(reprocessOrderDocNormListItem(orderDoc.id));

  /********** OTHER **********/

  const canBeEdited = () => orderDoc.type === OrderDoc.TYPE_E_SIGNED && [OrderDoc.STATUS_PENDING_SIGNATURES_PLACEMENT, OrderDoc.STATUS_PENDING_CUSTOMER_SIGNATURE].includes(orderDoc.status);

  const canBeDeleted = () => !orderDoc.isStdDoc;

  const isReady = () => orderDoc.status > OrderDoc.STATUS_PENDING_INITIAL_PROCESSING;

  const isProcessingFailed = () => orderDoc.status == OrderDoc.STATUS_INITIAL_PROCESSING_ERROR;

  const isStandardDocs = () => orderDoc.isStdDoc;

  const hasError = () => orderDoc.conflictsWithDeliveryOption;

  const canBeViewed = () => !isStandardDocs() && (deliveryOption === Order.DOC_DELIVERY_OPTION_UPLOAD || orderDoc.type !== OrderDoc.TYPE_INK_SIGNED);

  const getIcon = () => {
    switch (orderDoc.type) {
      case OrderDoc.TYPE_E_SIGNED:
        return esigndoc;
      case OrderDoc.TYPE_INK_SIGNED:
        return inksigndoc;
      case OrderDoc.TYPE_PREVIEW:
        return previewdoc;
      case OrderDoc.TYPE_SUPPORTING:
        return supportdoc;
    }
  }

  return <React.Fragment>
    {orderDoc && <tr className={classnames({ deleting: orderDoc.isDeleteInProgress, processing: orderDoc.status == OrderDoc.STATUS_PENDING_INITIAL_PROCESSING, error: hasError() })}>
      <td title={'Id: ' + orderDoc.id}>{num}</td>
      <td>
        <img src={filedoc} className="me-2" />
        {orderDoc.name}
        {hasError() && <React.Fragment>
          <i className="mdi mdi-alert-circle ms-2" id={'doc-err-' + orderDoc.id} />
          <UncontrolledTooltip placement="top" target={'doc-err-' + orderDoc.id}>This document is incompatible with the selected delivery option. Please remove or re-upload!</UncontrolledTooltip>
        </React.Fragment>}
      </td>
      <td>{!!getIcon() && <img src={getIcon()} className="me-2" />}{orderDoc.typeName}</td>
      <td className='text-center'>{getNotarizationRequired(orderDoc.isNotarizationRequired)}</td>
      <td className='text-center'>{orderDoc.numOfPages}</td>
      <td className='text-center'>{orderDoc.numOfSignatures}</td>
      <td className='text-center'>{orderDoc.numOfInitials}</td>
      <td>
        {(isReady() && canBeViewed()) && <a href={getBeUrl(`order-doc/${orderDoc.id}/pdf`)} target="_blank" rel="noreferrer" className="btn text-info mt-n2 mb-n2">
          <i className="mdi mdi-eye" />
        </a>}
        {isReady() && canBeEdited() && <Button type="button" color="default" className="text-success mt-n2 mb-n2" onClick={() => setIsEditActive(true)}>
          <i className="bx bxs-pencil" />
        </Button>}
        {canBeDeleted() &&
          <Button type="button" color="default" className="text-danger mt-n2 mb-n2" onClick={() => setIsConfirmationVisible(true)} disabled={orderDoc.isDeleteInProgress}>
            <i className="bx bxs-trash-alt" />
          </Button>
        }
        {!isReady() && isProcessingFailed() && <Button type="button" color="default" className="mt-n2 mb-n2 text-danger" onClick={restartProcessing}>
          <i className="mdi mdi-restart" />
        </Button>}
        {!isReady() && !isProcessingFailed() && <Button type="button" color="default" className="mt-n2 mb-n2">
          <i className="dripicons-hourglass bx bx-spin" />
        </Button>}
        {isConfirmationVisible && <Confirmation
          confirmBtnText="Delete"
          onConfirm={() => {
            setIsConfirmationVisible(false);
            removeOrderDoc();
          }}
          onCancel={() => setIsConfirmationVisible(false)}>
          <span style={{ color: '#556EE6' }}>Are you sure you want to delete document &quot;{orderDoc.name}&quot;?</span>
        </Confirmation>}
        {isEditActive && <div className="doc-viewer-wrapper">
          <DocViewer docId={orderDoc.id} closeHandler={() => setIsEditActive(false)} />
        </div>}
      </td>
    </tr>}
  </React.Fragment >
}

FormNewDocumentsDoc.propTypes = {
  id: PropTypes.number.isRequired,
  num: PropTypes.number.isRequired,
  refreshListHandler: PropTypes.func.isRequired,
  deliveryOption: PropTypes.number.isRequired,
};

export default FormNewDocumentsDoc;
